/* controls */

.Dropdown select {
  display: block;
}

.FormLabel {
  font-size: smaller;
  font-weight: 500;
  color: #09000a;
}

/* components */

.Content {
  max-width: 500px;
  max-height: 800px;
  height: 100%;
  justify-content: center;
  margin: auto;
}

.About {
  padding: 0px 8px;
}

.Main {
  display: flex;
  margin-top: 32px;
  margin-bottom: 8px;
  height: 100%;
}

.FretboardChart {
  width: 200px;
  height: 90%;
  min-height: 350px;
}

.FretMarker {
  transition: fill 1s ease;
}

.ControlPanel {
  margin: 0px 4px;
}

.ControlPanel > div:not(:first-child) {
  margin-top: 12px;
}

.ControlPanel > hr {
  border: 0;
  height: 1px;
  background: #333;
  margin-top: 24px;
}

.PitchClassPicker {
  display: flex;
}

.ScaleChordPicker {
  display: flex;
}

@media (max-width: 400px) {
  .ScaleChordPicker {
    display: block;
  }

  .ScaleChordPicker > *:not(:first-child) {
    margin-top: 4px;
  }
}

.FretCountPicker input {
  width: 32px;
  padding-left: 4px;
  display: block;
}

.DisplayPicker {
  display: flex;
}

.Footer {
  text-align: center;
  width: 100%;
}

.NoteList {
  line-height: 1.7;
  list-style: inside decimal;
  padding-left: 4px;
}

.NoteList svg {
  vertical-align: middle;
  font-size: 80%;
  font-family: 'Sawarabi Gothic', sans-serif;
}

.FretMarkerLabel {
  font-family: 'Sawarabi Gothic', sans-serif;
}
